import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Environment, PerspectiveCamera, Preload, Text } from '@react-three/drei';
import { EffectComposer, Bloom, ToneMapping } from '@react-three/postprocessing';
import { ToneMappingMode } from 'postprocessing';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { Cap, BP, Acryl } from '../components/product_meshes';
import { Blackboard } from '../components/info_meshes';
import * as THREE from 'three';
import { useSearchParams } from 'react-router';

const MAX_DIST=500, MIN_DIST=2, DEFAULT_FOV=45, CAMERA_POSITION=[0,0,200], BLACKBOARD_BASE_SCALE=100, MAX_SIZE=500, SHIFT=0;

// A simple Ruler that draws a vertical line from y=0..length in local space.
// We include a 'textRotation' prop so we can adjust the label orientation if needed.
function Ruler({ length, label, textRotation=0 }) {
  const DIM_COLOR="#aaa", TICK_SIZE=10, FONT_SIZE=16;

  // If textRotation===0 => it's the "vertical" axis, so put label to the left side
  // If textRotation===Math.PI/2 => it's horizontal, place label below/above the line
  let labelPosX= TICK_SIZE*1.5, labelAnchor="left";
  if(textRotation===0){
    // This is the vertical axis => shift label to the "left"
    labelPosX= -TICK_SIZE*1.5;
    labelAnchor="right";
  }

  return (
    <group>
      {/* Main line from (0,0) to (0,length) in local Y */}
      <mesh position={[0, length/2, 0]}>
        <boxGeometry args={[1, length, 1]} />
        <meshBasicMaterial color={DIM_COLOR} />
      </mesh>
      {/* Tick at bottom (y=0) */}
      <mesh position={[0, 0, 0]}>
        <boxGeometry args={[TICK_SIZE, 1, 1]} />
        <meshBasicMaterial color={DIM_COLOR} />
      </mesh>
      {/* Tick at top (y=length) */}
      <mesh position={[0, length, 0]}>
        <boxGeometry args={[TICK_SIZE, 1, 1]} />
        <meshBasicMaterial color={DIM_COLOR} />
      </mesh>
      {/* Label in the middle, rotated if desired */}
      <Text
        position={[labelPosX, length/2, 0]}
        fontSize={FONT_SIZE}
        color={DIM_COLOR}
        anchorX={labelAnchor}
        rotation={[0,0,textRotation]}
      >
        {label}
      </Text>
    </group>
  );
}

const Scene=({
  lights, setLights, windowWidth, windowHeight, modelId,
  onFirstRenderComplete, overrideColor
})=>{
  const [geometries, setGeometries] = useState([]);
  const [bpGeometry, setBpGeometry] = useState(null);
  const [acrylGeometry, setAcrylGeometry] = useState(null);
  const [width, setWidth] = useState(0), [height, setHeight] = useState(0);
  const [rendered, setRendered] = useState(false), renderCountRef = useRef(0);

  const [searchParams] = useSearchParams();
  const screencap = (searchParams.get("screencap")==="yes");
  const embed = (searchParams.get("embed")==="yes");
  const modelGroupRef = useRef();

  // Load the GLTF
  const loadGLTFModel = useCallback(async (url) => {
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("/draco/");
    loader.setDRACOLoader(dracoLoader);
    return new Promise((resolve, reject) => {
      loader.load(url, resolve, undefined, reject);
    });
  }, []);

  useEffect(() => {
    const gltfPath = (modelId === "axunio_logo.glb")
      ? "/models/axunio_logo.glb"
      : `/api/glb/${modelId}/`;

    loadGLTFModel(gltfPath).then((gltf) => {
      if(!gltf || !gltf.scene) return;
      // Compute bounding box for width/height
      const box=new THREE.Box3().setFromObject(gltf.scene), size=new THREE.Vector3();
      box.getSize(size);
      setWidth(size.x);
      setHeight(size.y);

      // Collect geometry
      const loadedGeometries=[];
      gltf.scene.traverse(child=>{
        if(child.isMesh){
          if(child.name==="geometry_0") setBpGeometry(child.geometry);
          else if(child.name==="geometry_1") setAcrylGeometry(child.geometry);
          else loadedGeometries.push([child.name, child.geometry]);
        }
      });
      setGeometries(loadedGeometries);
    }).catch(e=>console.error("Error loading GLTF model:",e));
  }, [modelId, loadGLTFModel]);

  // Render callbacks
  const handleRenderComplete=()=>{
    renderCountRef.current++;
    const totalItems= geometries.length + (bpGeometry?1:0) + (acrylGeometry?1:0);
    if(renderCountRef.current===totalItems) setRendered(true);
  };

  useEffect(()=>{
    if(rendered && typeof onFirstRenderComplete==="function"){
      onFirstRenderComplete();
      console.log("onFirstRenderComplete()");
    }
  },[rendered,onFirstRenderComplete]);

  // Scale logic
  const scalefactor=(width && height)?(MAX_SIZE/Math.max(width,height)):1;
  let scale_horizontal=1;
  if(width && height){
    const aspectRatio=windowWidth/windowHeight, modelRatio=width/height;
    if(aspectRatio < modelRatio*1.3){
      scale_horizontal= aspectRatio/(modelRatio*1.3);
    }
  }

  return (
    <Canvas id="reactcanvas" shadows gl={{ preserveDrawingBuffer:true }}
      style={{ width:"100%", height:"100%", backgroundColor:"black", position:"absolute", top:0, left:0, zIndex:0 }}
    >
      <PerspectiveCamera
        makeDefault
        position={(screencap||embed)?[0,0,10000]:CAMERA_POSITION}
        fov={DEFAULT_FOV}
      />
      <OrbitControls enablePan={false} enableRotate maxDistance={MAX_DIST} minDistance={MIN_DIST} minPolarAngle={0} maxPolarAngle={Math.PI*0.4}/>
      <Environment files="/env7.hdr" resolution={1024}/>
      <EffectComposer>
        <Bloom mipmapBlur luminanceThreshold={1} levels={8} intensity={0.5}/>
        <ToneMapping mode={ToneMappingMode.ACESFilmic}/>
      </EffectComposer>

      {/* Rotate entire scene so model lies flat */}
      <group rotation={[-Math.PI/2,0,0]}>
        <group scale={scale_horizontal*BLACKBOARD_BASE_SCALE/((height||1)*scalefactor)}>
          <Blackboard/>

          {width && height && (
            <group>
              {/*
                X Ruler: line is rotated -Math.PI/2 to be horizontal.
                We want the label to be horizontally below the line => textRotation = +Math.PI/2,
                and the label on the bottom side => see the logic in Ruler that sets anchor/position
              */}
              <group position={[-(width*scalefactor)/2, -(height*scalefactor)/2 * 1.2, 0]} rotation={[0,0,-Math.PI/2]}>
                <Ruler length={width*scalefactor} label={`${Math.round(width/10)} cm`} textRotation={Math.PI/2}/>
              </group>

              {/*
                Y Ruler: vertical line with no extra rotation => textRotation=0,
                label to the left => see logic in Ruler
              */}
              <group position={[-(width*scalefactor)/2 * 1.2, -(height*scalefactor)/2, 0]}>
                <Ruler length={height*scalefactor} label={`${Math.round(height/10)} cm`} textRotation={0}/>
              </group>
            </group>
          )}

          <group ref={modelGroupRef} scale={scalefactor}>
            {bpGeometry && <BP geometry={bpGeometry} callback={handleRenderComplete}/>}
            {acrylGeometry && <Acryl geometry={acrylGeometry} callback={handleRenderComplete}/>}
            {geometries.map(([name, geometry], i)=>
              <Cap key={i} name={name} geometry={geometry} lights={lights} setLights={setLights} callback={handleRenderComplete} overrideColor={overrideColor}/>
            )}
          </group>
        </group>
      </group>

      <Preload all/>
    </Canvas>
  );
};

export default Scene;
