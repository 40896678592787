import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import CropperComponent from '../components/CropperComponent';
import ImageUpload from '../components/ImageUpload';
import ExampleGallery from '../components/ExampleGallery';

const CROP_INITIAL = {
  unit: '%',
  x: 0,
  y: 0,
  width: 100,
  height: 100
};

export const HomePage = () => {
  const [loading, setLoading] = useState(0);
  const [image, setImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [crop, setCrop] = useState(CROP_INITIAL);
  const navigate = useNavigate();

  return (
    <div>
      <div style={{ maxWidth: '500px', margin: 'auto' }}>
        {!image && (
          <div style={{ paddingLeft: '8px', paddingRight: '8px' }}>
            <a href="https://neondelight.de"><img src="neondelight.png" style={{ margin: 'auto', maxWidth: 200 }} alt="Logo" /></a>
            Um dein individuelles Neonschild zu designen, wähle ein Beispielbild aus der Gallerie aus oder lade ein eigenes Bild hoch.
          </div>
        )}
        {showCropper && image && (
          <CropperComponent
            image={image}
            crop={crop}
            setCrop={setCrop}
            setLoading={setLoading}
            navigate={navigate}
            setImage={setImage}
            setShowCropper={setShowCropper}
          />
        )}
      </div>
      {!image && (
        <div>
          <br />
          <ImageUpload setImage={setImage} setShowCropper={setShowCropper} />
          <br />
          <ExampleGallery setImage={setImage} setShowCropper={setShowCropper} />
        </div>
      )}
    </div>
  );
};

export default HomePage;
