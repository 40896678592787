import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router';
import Scene from '../components/Scene';
import Toolbar from '../components/Toolbar';
import LoadingAnimation from '../utils/LoadingAnimation'; // Adjust the path as per your apps structure

const ProductPage = () => {
    const { modelId, color } = useParams();
    let [searchParams] = useSearchParams();

    const [isSceneRendered, setIsSceneRendered] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [lights, setLights] = useState(true); // used to be: searchParams.get('embed') == 'yes'
    const [overrideColor, setOverrideColor] = useState(null);

    const handleSceneRendered = () => {
        setIsSceneRendered(true);
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div id='thecanvasdiv' style={{ width: '100vw', height: '100vh', position: 'relative' }}>
            <LoadingAnimation rendered={isSceneRendered} />
            {modelId != 'loading' && <Scene
                modelId={modelId}
                lights={lights}
                setLights={setLights}
                windowWidth={windowWidth}
                windowHeight={windowHeight}
                onFirstRenderComplete={handleSceneRendered}
                overrideColor={overrideColor}
            />}
            {modelId != 'loading' && <Toolbar
                lights={lights}
                setLights={setLights}
                windowWidth={windowWidth}
                windowHeight={windowHeight}
                overrideColor={overrideColor}
                setOverrideColor={setOverrideColor}
            />}
        </div>
    );
};

export default ProductPage;
