
export default async function fetchProductionFiles(modelId) {
  console.log("fetchProductionFiles gets executed!")
  try {
    const response = await fetch(`/api/generate-production-files/${modelId}/`, {
      method: 'POST',
    });
    if (!response.ok) {
      throw new Error('Failed to generate production files');
    }
    // Read the file as a Blob
    const blob = await response.blob();

    // Trigger a download
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'production_files.zip';
    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error generating production files:', error);
    alert('Fehler beim Generieren der Modelle.');
  }
}
